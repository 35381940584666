body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*#f2a100*/
*{
  margin: 0;
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.backtoTopBtn{
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}
.backtoTopBtn button{
  height: 40px;
    width: 40px;
    padding: 0;
    line-height: 0;
    border-radius: 100%;
}
.backtoTopBtn button svg{
  font-size: 20px;
}
.header-menu ul li{
  padding: 14px 10px;
}
.swiper-button-prev,.swiper-button-next{
  background: #ebd46a;
    border-radius: 100%;
    height: 30px !important;
    width: 30px !important;
}
.swiper-button-prev:after,.swiper-button-next:after{
  font-size: 16px !important;
    color: #fff;
}
.swiper-slide-thumb-active{
  border: 2px solid #ebd46a;
}
.single-form .singleSelect > div{
  border-radius: 0;
}
.single-form .singleSelect > div > div{
  font-size: 14px;
  font-style: italic;
  padding: 0 20px;
  border-radius: 0;
}

.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}

.loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid rgba(0, 0, 0, 0.719);
  border-radius: 50%;
  animation: loadingRotate 800ms linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}
.page-pagination ul{
  justify-content: center;
}
.widget-price .MuiSlider-root{
  color: #ebd46a;
}
.checkbox-items li span.active,.header-menu li a.active,.canvas-menu ul li a.active{
    color: #ebd46a;
    transition: .2s;
}
.checkbox-items li span{
  font-weight: 600;
    font-size: 16px;
    transition: .2s;
    color: #666;
    font-family: "Rajdhani",sans-serif;
    cursor: pointer;
}
.header-area{
  background-color: #fff;
  padding: 10px 0;
}
.passwordField{
  position: relative;
}
.passwordField svg{
  position: absolute;
  right: 10px;
  color: grey;
    top: 18px;
}
#registerImage{
  position: relative;
  margin: 30px auto;
    width: 100px;
}
#registerImage > img {
  width: 100px;
    border-radius: 100%;
    height: 100px;
    cursor: pointer;
    object-fit: scale-down;
    border: 1px solid #d7d7d7;
}
#registerImage > input {
  display: flex;
    padding: 0%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 100%;
}
.speedDial{
  position: fixed;
  right: 30px;
  top: 60px;
  z-index: 9999;
}
.speedDialIcon{
  width: 56px;
  height: 56px;
  border-radius: 100%;
}
.profileContainer,.update_profile,.update_password,.login-register-panel,.forgot_password,.cart_sec,.orderDetailsPage,.dashboard-panel{
  padding: 180px 0 100px;
}
.profile_image{
  display: flex;
  flex-direction: column;
}
.profile_image img{
  width: 200px;
    height: 200px;
    border-radius: 100%;
    margin: 30px 0;
}
.profile_content > div{
  margin: 20px 0;
}
.profile_content > div a:first-child{
  margin-right: 10px;
}
@media only screen and (max-width: 992px){
  .profile_content > div a:first-child{
    margin-bottom: 10px;
  }
}
.product-details-description .product-quantity input{
  width: 50px;
}
.single-form select,.paymentContainer .paymentInput{
  width: 100%;
    height: 50px;
    padding: 0 20px 0 40px;
    font-size: 14px;
    font-style: italic;
    color: #999;
    transition: all .3s linear;
    border: 1px solid #d7d7d7;
    border-radius: 0;
    background-color: #fff;
}
.single-form select:focus{
  border-color: #ebd46a;
    outline: none;
}
.shippingBox .single-form{
  position: relative;
}
.shippingBox .single-form svg,.paymentContainer .MuiSvgIcon-root{
  position: absolute;
    top: 16px;
    left: 10px;
    font-size: 18px;
}
.shippingBox .single-form input:not([type=checkbox]):not([type=radio]){
  padding: 0 20px 0 40px;
}
.confirmOrderPage{
    padding-top: 30px;
}
.confirmshippingArea,.orderSummary,.confirmCartItems,.orderDetailsContainerBox,.orderDetailsCartItems{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    margin-bottom: 20px;
}
.confirmshippingAreaBox,.confirmCartItemsContainer{
  padding-top: 20px;
}
.confirmshippingAreaBox p{
  margin: 0;
  padding-right: 10px;
}
.confirmshippingAreaBox > div{
  padding-bottom: 4px;
  display: flex;
}
.confirmCartItems > div img{
  width: 70px;
    height: 70px;
}
.confirmCartItemsContainer > div,.orderSummaryTotal,.orderSummary > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirmCartItemsContainer h6{
  margin-right: auto;
  margin-left: 10px;
  text-transform: capitalize;
}
.orderSummary h3{
  padding-bottom:20px;
}
.orderSummary p{
  margin: 0;
  padding-bottom: 10px;
}
.orderSummary button{
  margin-top: 10px;
  width: 100%;
}
.confirmCartItemsContainer > div{
  margin-bottom: 10px;
}
.confirmCartItemsContainer {
  height: 250px;
    overflow-y: auto;
    padding-right: 20px;
}
.confirmCartItemsContainer::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #000;
}

.confirmCartItemsContainer::-webkit-scrollbar
{
  width: 6px;
  background-color: #ebd46a;
}

.confirmCartItemsContainer::-webkit-scrollbar-thumb
{
  background-color: #ebd46a;
  border: 2px solid #ebd46a;
}
.paymentForm > div{
  position: relative;
  margin-bottom: 20px;
}
.paymentInput > div{
  top: 16px;
}
.paymentForm h3{
  padding-bottom: 30px;
    padding-top: 20px;
}
.InputContainer .InputElement:focus .paymentContainer .paymentInput{
  border: 1px solid #ebd46a;
}
.orderSuccess{
  display: flex;
  align-items: center;
    flex-direction: column;
}
.orderSuccess svg{
  font-size: 90px;
  color: #ebd46a; 
}
.orderSuccess h3{
  margin: 10px 0 40px;
}
.orderDetailsCartItemsContainer > div > img {
  width: 70px;
    height: 70px;
}
.orderDetailsContainerBox > div{
  display: flex;
}
.orderDetailsContainerBox p{
  margin: 0;
  padding-bottom: 4px;
}
.orderDetailsContainerBox h4{
  padding-bottom: 20px;
}
.orderDetailsContainerBox span{
  padding-left: 14px;
}
.orderDetailsContainer .orderDetailsContainerBox{
  height: 100%;
}
.orderDetailsCartItemsContainer > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.orderDetailsCartItemsContainer a{
  margin-right: auto;
  margin-left: 10px;
}
.dashboard-panel .sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid darkgrey;
}
.dashboard-panel .sidebar a{
  padding: 10px 0;
}
.dashboard-panel .sidebar p{
  font-weight: 500;
}
.dashboard-panel .sidebar .MuiCollapse-wrapperInner{
  display: flex;
  flex-direction: column;
}
.dashboard-panel .sidebar .MuiTypography-body1{
font-size: inherit;
    font-family: unset;
    font-weight: 500;
}
.dashboardSummaryBox2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  flex-wrap: wrap;
}
.dashboardSummaryBox2 p{
  margin: 0;
}
.dashboardSummaryBox2 a{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 22%;
}
.dashboardSummaryBox2 span{
  font-weight: 500;
}
.createProductForm .single-form{
  position: relative;
}
.createProductForm .single-form svg{
  position: absolute;
    top: 16px;
    font-size: 20px;
    left: 10px;
}
.createProductForm .single-form input:not([type=checkbox]):not([type=radio]),.createProductForm .single-form textarea{
  padding: 0 20px 0px 40px;
}
.createProductForm .single-form textarea{
  padding-top: 10px;
  height: 150px;
}
#createProductFormImage img,#createCateFormImage img{
  width: 100px;
  height: 100px;
  margin: 10px;
  object-fit: scale-down;
}
.MuiPaper-root{
  margin-top: 20px;
  padding: 10px;
}
.updatePasswordForm .single-form{
  position: relative;
}
.password_icon{
  position: absolute;
  right: 10px;
  top: 0px;
}
.logoUpload #registerImage{
    width: 180px;
    margin: 30px 0px 30px;
}
.logoUpload #registerImage > img{
  width: 180px;
    border-radius: 10px;
    object-fit: scale-down;
    height: 180px;
}
.logo-status{
  position: relative;
}
.logo-status svg{
  position: absolute;
    top: 15px;
    left: 10px;
}
.swiper-slide .slider-content,.swiper-slide .slider-images{
  display: none;
}
.swiper-slide-active .slider-content,.swiper-slide-active .slider-images{
  display: block;
}
.header-menu ul li a,.page-banner-content .title,.page-banner-content .breadcrumb li,.contact-info .title,
.contact-form .single-form .btn,.section-title-03 .title{
  text-transform: capitalize;
}
.call-to-action-content p{
  color: #383838;
}
.footer-widget .widget-about p small a{
  display: inline-block;
    font-size: 14px;
    padding-left: 4px;
}
.faq-body{
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.faq-row{
  width: 48%;
}
.faq-row .row-title{
  font-size: 15px !important;
  font-weight: 500;
  color: #252c51 !important;
}
.row-content-text{
  font-size: 14px !important;
}
.tileGuideSec ul{
  padding-left: 20px;
}
.tileGuideSec li{
  list-style: disc;
}
.tileGuideImg {
  text-align: center;
}
.tileGuideImg img{
  width: 600px;
  height: 600px;
}
.slider-content .title{
  font-size: 42px;
}
.modal-content{
  padding: 20px;
}
.single-slider{
  height: 100vh;
}
.custom_hero_slider .slider-images{
  top: 0;
    left: 0;
    width: 100%;
}
.custom_hero_slider .slider-images img{
  position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}
.custom_hero_slider .content{
  text-align: center;
    width: 100%;
    max-width: 100%;
    align-items: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-top: 150px;
}
.custom_hero_slider .content p{
  margin-top: 0;
  font-size: 22px;
    color: #252c51;
}
.custom_hero_slider .swiper-pagination{
  text-align: center;
}
.custom_hero_slider .custom_slide{
  height: 100vh;
}
.custom_slide .content h2{
    width: 40%;
    /*background: radial-gradient(#ebd46a, transparent);*/
    padding: 0 10px;
}
.custom_hero_slider .custom_slide .btn{
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
}
.importFileModal .modal-dialog{
  width: 50%;
}
@media only screen and (max-width: 576px){
  .importFileModal .modal-dialog{
    width: 100%;
    margin: 30px auto;
  }
  .custom_slide .content h2{
    font-size: 22px;
    width: 90%;
  }
  .custom_hero_slider .slider-images img,.custom_hero_slider .custom_slide,.custom_hero_slider .content{
    height: 60vh;
  }
  .custom_hero_slider .content{
    padding-top: 190px;
  }
  .dashboardSummaryBox2 a{
    width: 42%;
    margin: 6px;
  }
}
.custom_blogs .single-blog{
  border: 1px solid lightgrey;
    padding: 20px;
    border-radius: 10px; 
}
.blog_detail_content{
  padding: 40px;
}
.page-banner-section{
  position: relative;
}
.overlayBanner{
  position: absolute;
    background: rgb(0,0,0,.5);
    height: 100%;
    width: 100%;
}
.page-banner-content{
  z-index: 1;
    position: relative;
}
.breadcrumb li a{
  color: #ebd46a;
}
.custom_hero_slider .swiper-slide .content{
  display: none;
}
.custom_hero_slider .swiper-slide-active .content{
  display: flex;
}
.custom_hero_slider .slider-images{
  position: absolute;
}
.single-blog .blog-content .title{
  font-size: 18px;
  display: block;
}
.single-blog .blog-content .btn{
  height: 30px;
    line-height: 30px;
    padding: 0 16px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 12px;
}
.single-blog .blog-content .blog-meta li{
  font-size: 12px;
}
.single-blog .blog-content .blog-meta li i{
  margin-right: 4px;
    margin-top: -2px;
    font-size: 18px;
}
.single-blog>a img{
  height: 210px;
}
.single-products-banner img{
  height: 337px;
}
.single-products-banner i{
  line-height: 1;
}
.copyright-text{
  padding: 6px 0;
}
.custom_blogs ul{
  list-style-type: disc !important;
    padding-left: 20px !important;
}
.single-blog .blog-content .blog-meta{
  padding-left: 0 !important;
}